.cmn-table {
  .ant-table {
    height: calc(76vh - 50px);
    border: 1px solid #b7b7b7;
    border-radius: 8px;
    font-family: "cera_promedium";
    font-size: 16px;
  }
  .ant-table-placeholder {
    height: calc(65vh - 20px);
    .ant-table-cell {
      border-bottom: none;
    }
  }
  .ant-table-thead {
    tr th {
      background-color: #999999;
      color: rgb(255, 255, 255);
      border-right: 1px solid #ffffff;
      &::before {
        content: unset !important;
      }
      .ant-table-column-sorter {
        color: #ffffff;
      }
      &.ant-table-column-has-sorters {
        background-color: #999999;
        &:hover {
          background-color: #999999;
          .ant-table-column-sorter {
            color: #ffffff;
          }
        }
      }
      &:last-child {
        border-right: none;
      }
    }
  }
  .ant-table-body {
    margin-right: -6px;
  }
  .ant-table-tbody {
    tr {
      td {
        border-right: 1px solid #b7b7b7;
        border-bottom: 1px solid #b7b7b7;
        color: $theme-color;
        &:last-child {
          border-right: none;
        }
      }
    }
  }
  .ant-pagination {
    li {
      border: 1px solid #dfe3e8;
      a {
        color: $theme-color;
      }
      &.ant-pagination-item-active {
        border-color: #272725;
        a {
          color: $theme-color;
        }
      }
    }
  }
}

.status {
  border-radius: 20px;
  text-align: center;
  padding: 4px;
  &-active {
    background: $theme-color;
    color: #fff;
  }
  &-inactive {
    background: rgb(128, 128, 128) !important;
    color: #fff;
  }
}

.dot {
  width: 12px;
  height: 12px;
  background: red;
  display: inline-block;
  border-radius: 50%;
  margin-right: 8px;
}

.groups {
  .ant-table {
    height: calc(51vh - 38px);
  }
}

.salesforce-notification {
  .ant-table {
    height: calc(51vh - 38px);
  }
}

.selected-customers {
  position: absolute;
  bottom: 10px;
  width: 390px;
  &-count {
    border-radius: 8px;
    border: 1px solid black;
    padding: 8px 0px 8px 8px;
    background-color: #fff;
    .ant-select-selector {
      border: none !important;
    }
  }
  .ant-select {
    width: 170px;
  }
  button {
    background-color: #272725;
    border-color: #272725;
    color: white;
    font-weight: 500;
    font-size: 14px;
    border-radius: 16px;
    padding: 4px 5px;
    border-radius: 8px;
    min-width: 70px;
    font-family: "Poppins", sans-serif;
    &:hover {
      color: white !important;
    }
  }

  button:disabled {
    background-color: #e4e4e4 !important;
  }
}

.ant-table-wrapper
  .ant-table-tbody
  .ant-table-row.ant-table-row-selected
  > .ant-table-cell {
  background-color: #efefef;
}

.ant-checkbox-checked .ant-checkbox-inner {
  background-color: #000;
  border-color: #000;
}

.ant-checkbox:not(.ant-checkbox-disabled):hover .ant-checkbox-inner {
  background-color: #e4e4e4;
  border-color: #000;
}

.ant-checkbox-indeterminate .ant-checkbox-inner:after {
  background-color: #000;
}

.ant-checkbox-wrapper:not(.ant-checkbox-wrapper-disabled):hover
  .ant-checkbox-checked:not(.ant-checkbox-disabled)
  .ant-checkbox-inner {
  background-color: #000;
  border-color: #000;
}

.dot {
  width: 12px;
  height: 12px;
  background: red;
  display: inline-block;
  border-radius: 50%;
  margin-right: 8px;
}
