.dashboard-cards {
  // flex-wrap: nowrap;
  // overflow-x: scroll;
}
.ant-card {
  .ant-card-body {
    border-radius: 0 0 6px 6px;
    margin-bottom: 16px;
    padding: 5px 24px;
    .connected-user {
      display: flex;
      justify-content: space-between;
      font-size: 14px;
      font-weight: 500;
      line-height: 17.6px;
      color: #242424;
      align-items: center;
      .user-count {
        width: 45px;
        height: 21px;
        border-radius: 2px;
        border: 0.6px solid #000;
        text-align: center;
        font-size: 14px;
        font-weight: 700;
        line-height: 20px;
      }
      .sub {
        font-size: 8px;
      }
    }
  }
  .ant-card-head {
    background: #999999;
    color: white;
    font-weight: 500;
    font-size: 14px;
    text-transform: uppercase;
    letter-spacing: 3px;
    border-radius: 6px 6px 0 0;
  }
}
