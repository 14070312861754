.template-modal {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  border-radius: 30px;
  input {
    height: 50px !important;
  }
  .description,
  .title {
    padding: 10px;
    border-radius: 4px;
    margin: 0;
  }
  .title {
    border-radius: 4px;
    font-size: 20px;
    margin-bottom: 12px;
  }
  .tempalte-profile-pic-box {
    width: 100%;
    border-radius: 5px;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 1px 1px 15px #909090;
    background: white;
    min-height: 120px;
    height: 140px;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  .template-row {
    border-radius: 17px;
    border-radius: 17px;
    border: 6px solid #fff;
    padding: 40px;
    justify-content: center;
  }
  .template-profile-box {
    border-radius: 4px;
  }
  .tempalte-profile-pic {
    display: flex;
    flex-wrap: wrap;
    height: 100%;
    &.five-profile-pic {
      justify-content: space-between;
      .tempalte-profile-pic-box {
        width: 19%;
      }
    }
  }
}
.template-modal-outer {
  .ant-modal-content,
  .ant-modal-header {
    background-color: #efefef;
  }
}

.edit-Modal-outer {
  .ant-modal-content,
  .ant-modal-header {
    background-color: #ffffff;
  }
}

.preview {
  &-container {
    padding: 20px 40px;
    background-color: #ededed;
    border-radius: 17px;
    margin-bottom: 10px;
    box-shadow: 0px 0px 13px 0px rgba(0, 0, 0, 0.25);
    h2 {
      text-align: center;
      margin-top: 0px;
      margin-bottom: 16px;
    }
  }
  &-edit {
    h4 {
      font-size: 18px;
      font-weight: 700;
      letter-spacing: 0.21px;
      margin-bottom: 10px;
    }
  }
}

.background {
  &-container {
    display: flex;
    align-items: center;
  }
  &-item {
    padding: 10px 12px;
    font-size: 20px;
    border-radius: 4px;
    background-color: #ededed;
    margin-right: 10px;
    display: flex;
    cursor: pointer;
    img {
      height: 20px;
      width: 20px;
    }
  }
}

.blur-slider {
  margin-top: 20px;
  .ant-slider-handle::after {
    box-shadow: 0 0 0 2px #000000 !important;
  }
  .ant-slider-track {
    background-color: #000000;
  }
}

.blur-slider:hover {
  .ant-slider-track {
    background-color: #000000;
  }
}
